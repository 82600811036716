body{
  overflow:hidden;
  height:95vh;
}
.swipe-right-enter-active {
  opacity: 1;
}
.swipe-left-enter-active {
  opacity: 1;
  animation:enterleft 0.5s;
}
.swipe-right-enter-active {
  opacity: 1;
  animation:enterright 0.5s;
}


.swipe-left-exit-active {
  opacity: 0;
  animation:exitleft 0.5s !important;
}
.swipe-right-exit-active {
  opacity: 0;  
  animation:exitright 0.5s !important;
}
.flip-card {
  perspective: 1000px;
  width: 300px; /* Set a consistent width */.flip-card {
    perspective: 1000px;
    width: 300px; /* Set a consistent width */
    height: 300px; /* Set a consistent height */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  height: 300px; /* Set a consistent height */
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
}
.cross-container {
  position: relative;
  width: 100%; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed, ensuring it contains the <h1> */
  display: flex;
  align-items: center; /* Centers the <h1> vertically */
  justify-content: center; /* Centers the <h1> horizontally */
  overflow: hidden; /* Ensures the lines do not spill outside the container */
}
.MuiButton-icon{ margin:0 !important; }
.cross-container > *::before{
  content: '';
  position: absolute;
  top: 0%;
  border-right:1px dotted #000;
  left: 0%;
  width: 50%; /* Makes the horizontal line span the entire width of the container */
  height: 100%; /* Height of the horizontal line */
}
.cross-container > *::after{
  content: '';
  position: absolute;
  border-top:1px dotted #000;
  top: 50%;
  left: 0%;
  width: 100%; /* Makes the horizontal line span the entire width of the container */
  height: 500%; /* Height of the horizontal line */
}
/* Styling for the <h1> element */
h1.cross-border {
  position: relative;
  z-index: 1; /* Ensures the text appears above the crossing lines */
}
.flip-card .MuiCard-root {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-card.flipped .MuiCard-root {
  transform: rotateY(180deg);
}

.flip-card .MuiCard-root .MuiCardContent-root {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.flip-card .MuiCard-root .MuiCardContent-root:nth-child(2) {
  transform: rotateY(180deg);
}

@keyframes enterleft {
  0% {
    transform: rotateZ(20deg) translateX(100px) scale(110%);
    opacity: 0;
  }
  100% {
    transform: rotateZ(0) translateX(0px) scale(100%);
    opacity: 1;
  }
}
@keyframes enterright {
  0% {
    transform: rotateZ(-20deg) translateX(-100px) scale(110%);
    opacity: 0;
  }
  100% {
    transform: rotateZ(0) translateX(0px) scale(100%);
    opacity: 1;
  }
}
@keyframes exitleft {
  0% {
    transform: rotateZ(0) translateX(-5px) scale(100%);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-20deg) translateX(-100px) scale(90%);
    opacity: 0;

  }
}
@keyframes exitright {
  0% {
    transform: rotateZ(0) translateX(5px) scale(100%);
    opacity: 1;
  }
  100% {
    transform: rotateZ(20deg) translateX(100px) scale(90%);
    opacity: 0;

  }
}

.flip-card.flipped .MuiCard-root {
  animation: flipIn 0.6s forwards;
}

.flip-card .MuiCard-root {
  animation: flipOut 0.6s forwards;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #ece0e0;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}